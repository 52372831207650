<template>
  <div class="min-h-screen flex flex-col bg-white w-full">
    <main class="flex-grow flex items-center justify-center">
      <Card class="w-full">
        <template v-slot:card-header>
          <div id="logo" class="mb-10">
            <Logo
              :path="logo"
              width="120"
              className="flex justify-center"
            ></Logo>
          </div>
        </template>
        <template v-slot:card-body>
          <div
            class="flex flex-col-reverse md:flex-row items-center justify-between space-x-0 md:space-x-24"
          >
            <div class="w-full md:w-2/3">
              <h1 class="text-2xl font-semibold my-3">
                THANKS FOR TRYING THE FREE TRIAL
              </h1>
              <p class="text-gray-400 mb-6">
                Your free trial has expired; thank you for trying Mastermind
                Cognitive Training! Want to continue your experience? Click the
                subscribe button below. 
              </p>
              <div
                v-if="buttonsAvailable"
                class="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4"
              >
                <button
                  @click="handleClose"
                  class="border-2 font-bold text-blue py-2 px-3 flex-1"
                  style="background-color: white; border-color: #1d2e48"
                >
                  Close
                </button>
                <button
                  @click="handleContinue"
                  class="bg-blue-500 font-bold hover:bg-blue-700 text-blue py-2 px-3 flex-1"
                  style="
                    box-shadow: 6px 6px 0px 0px #1d2e48;
                    background-color: #edfe86;
                  "
                >
                  Subscribe
                </button>
              </div>
            </div>
            <div class="w-full md:w-2/3 flex justify-center md:mt-0">
              <img
                src="@/assets/free-trial.svg"
                alt="Mastermind Cognitive Training"
                class="w-full h-auto"
              />
            </div>
          </div>
        </template>
      </Card>
    </main>
    <ContactFooter class="" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { createClient } from "@/services/stripe/stripe.js";
import { getFreeTrialData } from "@/services/user/user";

import Card from "@/components/auth/Card.vue";
import Logo from "@/components/auth/Logo.vue";
import ContactFooter from "@/components/general/ContactFooter.vue";

const store = useStore();
const router = useRouter();

const logo = ref(require("@/assets/icons/mm-icon.svg"));

const user = computed(() => store.getters["user/getUserData"]);

const buttonsAvailable = ref(true);

onMounted(async () => {
  try {
    await getFreeTrialData();
  } catch (error) {
    buttonsAvailable.value = false;
  }
});

async function handleContinue() {
  const email = user.value["email"];
  const phoneNumber = user.value["phoneNumber"];
  const userName = user.value["userName"];

  const stripeClient = await createClient(email, userName, true);

  router.push({
    name: "Check Out",
    query: {
      customerId: stripeClient.data.customer.id,
      email,
      phoneNumber,
      isFreeTrial: true,
    },
  });
}

function handleClose() {
  store.commit("user/logout");
  router.push("/login");
}
</script>
